<template>
    <div class="content-layout padded">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1 class="page-heading">
                        Log in to your account
                    </h1>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-8">
                    <div class="auth-container">
                        <login-form @login-success="login"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoginForm from '~/components/auth/LoginForm'

export default {
    components: { LoginForm },
    middleware: 'guest',
    data () {
        return {}
    },

    head () {
        return {
            title: 'Login - Truestock'
        }
    },
    mounted () {
        if (this.$route.query.a === 't') {
            this.$toast.success('Your account is already activated. Please login.')
            this.$router.push({ name: 'login' })
        }
    },
    events: {
        loginSuccess (payload) {
            this.$router.back()
        }
    },
    methods: {
        login () {
            // Redirect home.
            this.$router.push({ name: 'welcome' })
        }
    }
}
</script>
